<template>
    <div class="content_area">
        <div class="content_wpr">
            <div class="section_content w-100">
                <div class="section_header">
                    <h2>Marketplace <span>Manage all your additional services here.</span></h2>
                </div>
                <div class="no-portal-chat">
                    <h2>Comming Soon !</h2>
                </div>
            </div>
        </div>
    </div>
</template>
